import React, {Component} from 'react'
import {getRelativeTime} from "../../utils/dateUtils";
import ImageComponent from "../../utils/ImageComponent";
import {CiLock} from "react-icons/ci";
import {BiWorld} from "react-icons/bi";

export default class ItemBlogList extends Component {
    state = {
        widthImage: 0,
        heightImage: 0,
        displayImage: false
    };

    componentDidMount() {
        const img = new Image();
        img.onload = () => {
            this.setState({
                widthImage: img.width,
                heightImage: img.height
            });
        };
        img.src = this.props.imageUrl;
    }

    render() {
        const {shortDescription, slug, title, imageUrl, createdAt, user, status} = this.props;
        const relativeTime = getRelativeTime(createdAt);
        const {widthImage, heightImage} = this.state;

        return (
            <div className={"blogs-content-center-card w-full border rounded-2xl p-4"}>
                <div className="blogs-content-center-item-header grid grid-cols-6 gap-3">
                    {
                        heightImage / widthImage >= 1.2 && (
                            <div className={"col-span-2"}>
                                {(imageUrl?.length ?? 0) > 0 && (
                                    <a href={`/${user.username}/blog/${slug}`} className="block">
                                        <img src={imageUrl}
                                             className="w-full h-full object-contain rounded-lg"
                                             alt="Blog Thumbnail"
                                        />
                                    </a>
                                )}
                            </div>
                        )
                    }
                    <div className={heightImage / widthImage > 1.2 ? "col-span-4" : "col-span-6"}>
                        <div className="flex justify-between">
                            <div className="flex left-item-header-title">
                                <div className="blogs-content-center-item-header-avatar ">
                                    <ImageComponent className={"mr-2 mt-1 w-10 h-10"} image={user.profileImageUrl}
                                                    alt={user.displayName}/>
                                </div>
                                <div className="blogs-content-center-item-header-info ">
                                        <span className={"text-[#A1A1AA] flex items-center gap-2"}>
                                            <a href={`/user/${user.username}`}>{user.displayName}</a>
                                            {
                                                status === "PRIVATE" ? <CiLock className={"text-[12px]"}/> :
                                                    <BiWorld className={"text-[12px]"}/>
                                            }
                                        </span>
                                    <a href={`/user/${user.username}`}
                                          className={"text-[#A1A1AA70] text-sm"}>{user.username}</a>
                                </div>
                            </div>
                            <div className="right-item-header-title mt-1 flex flex-col items-end">
                                <span className="text-[#A1A1AA70] text-sm">{relativeTime}</span>
                            </div>
                        </div>
                        <div className="blogs-content-center-item-body mt-3">
                            <a href={`/${user.username}/blog/${slug}`}
                                  className="text-xl font-semibold text-[#F4F4F5] mb-2"><h1>{title}</h1></a>
                            <div className="text-[#A1A1AA] text-base line-clamp-3 overflow-hidden">
                                {shortDescription}
                            </div>
                            {heightImage / widthImage < 1.2 && (imageUrl?.length ?? 0) > 0 && (
                                <a href={`/${user.username}/blog/${slug}`} className="mt-3 block">
                                    <img src={imageUrl}
                                         className="w-full max-h-96 object-contain rounded-lg"
                                         alt="Blog Thumbnail"
                                    />
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}