import React, {Fragment, useEffect, useRef, useState} from 'react';
import {
    FaBold, FaItalic, FaUnderline, FaAlignLeft, FaAlignCenter,
    FaAlignRight, FaListUl, FaListOl, FaLink, FaTable, FaImage, FaQuoteRight, FaRedo, FaUndo, FaMinus
} from 'react-icons/fa';
import {ImTextColor} from "react-icons/im";
import {MdFormatColorFill} from "react-icons/md";
import {IoCodeSharp} from "react-icons/io5";
import {API_BASE_URL} from "../../config";
import {useCookies} from "../../cookieContext";

const RichTextEditor = ({content, setContent}) => {
        const [showLinkDialog, setShowLinkDialog] = useState(false);
        const [linkUrl, setLinkUrl] = useState('');
        const [linkTitle, setLinkTitle] = useState('');
        const editorRef = useRef(null);
        const [savedSelection, setSavedSelection] = useState(null);
        const [showImageDialog, setShowImageDialog] = useState(false);
        const [imageUrl, setImageUrl] = useState('');
        const [uploadedImage, setUploadedImage] = useState(null);
        const [canUndo, setCanUndo] = useState(false);
        const [canRedo, setCanRedo] = useState(false);
        const [error, setError] = useState(false);

        const {cookies} = useCookies();
        const fontFamilies = [
            {name: 'Lora (Default)', class: 'font-lora'},
            {name: 'Courier Prime', class: 'font-courier-prime'},
            {name: 'Merriweather', class: 'font-merriweather'},
            {name: 'Poppins', class: 'font-poppins'},
            {name: 'Roboto', class: 'font-roboto'},
        ];

        useEffect(() => {
            if (editorRef.current && content !== editorRef.current.innerHTML) {
                editorRef.current.innerHTML = content;
            }
        }, [content]);

        useEffect(() => {
            window.copyCode = (id) => {
                const code = document.getElementById(id);
                if (code) {
                    const range = document.createRange();
                    range.selectNode(code);
                    window.getSelection().removeAllRanges();
                    window.getSelection().addRange(range);
                    document.execCommand('copy');
                    window.getSelection().removeAllRanges();
                    alert('Code copied to clipboard!');
                }
            };
        }, []);

        const execCommand = (command, value = null) => {
            switch (command) {
                case 'applyFontFamily':
                    document.execCommand('fontName', false, value);
                    setTimeout(() => {
                        const selection = window.getSelection();
                        if (selection.rangeCount > 0) {
                            const range = selection.getRangeAt(0);
                            const span = document.createElement('span');
                            span.className = value;
                            range.surroundContents(span);
                        }
                    }, 0);
                    break;
                case 'insertUnorderedList':
                case 'insertOrderedList':
                    document.execCommand(command, false, value);
                    setTimeout(() => {
                        const lists = editorRef.current.querySelectorAll('ul, ol');
                        lists.forEach(list => {
                            list.classList.add('list-inside', 'pl-4', 'my-2');
                            if (list.tagName === 'UL') {
                                list.classList.add('list-disc');
                            } else if (list.tagName === 'OL') {
                                list.classList.add('list-decimal');
                            }
                            const items = list.querySelectorAll('li');
                            items.forEach(item => {
                                item.classList.add('mb-1');
                            });
                        });
                    }, 0);
                    break;

                case 'createLink':
                    if (savedSelection) {
                        const selection = window.getSelection();
                        selection.removeAllRanges();
                        selection.addRange(savedSelection);

                        const linkHtml = `<a href="${linkUrl}" target="_blank" rel="noopener noreferrer" class="text-blue-500 underline cursor-pointer" style="font-family: inherit;">${linkTitle}</a>`;
                        document.execCommand('insertHTML', false, linkHtml);

                        const links = editorRef.current.querySelectorAll('a');
                        links.forEach(link => {
                            if (link.href === linkUrl) {
                                link.style.fontFamily = 'inherit';
                                link.addEventListener('click', (e) => {
                                    e.preventDefault();
                                    window.open(link.href, '_blank');
                                });
                            }
                        });
                    }
                    setShowLinkDialog(false);
                    setLinkUrl('');
                    setLinkTitle('');
                    setSavedSelection(null);
                    break;

                case 'formatBlock':
                    document.execCommand(command, false, value);
                    setTimeout(() => {
                        const blocks = editorRef.current.querySelectorAll(value.replace(/[<>]/g, ''));
                        blocks.forEach(block => {
                            if (value === '<blockquote>') {
                                block.className = 'border-l-4 border-gray-500 pl-4 italic text-gray-400 my-0';
                            } else if (value.startsWith('<h')) {
                                block.className = 'font-bold my-2';
                            }
                        });
                    }, 0);
                    break;

                case 'insertCodeBlock':
                    const id = 'code-' + Date.now();
                    const codeBlock = `
                        <div class="relative bg-gray-600 rounded-md p-4 my-4 overflow-x-auto max-w-full">
                            <pre class="whitespace-pre overflow-x-scroll scrollbar-hide"><code id="${id}" class="block text-sm text-gray-300">${value || 'Your code here'}</code></pre>
                            <button onclick="copyCode('${id}')" class="absolute top-2 right-2 p-1 bg-gray-700 hover:bg-gray-600 rounded">
                                <svg class="w-4 h-4 text-gray-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"></path>
                                </svg>
                            </button>
                        </div>
                    `;
                    document.execCommand('insertHTML', false, codeBlock);
                    break;

                default:
                    document.execCommand(command, false, value);
            }
            editorRef.current.focus();
        };

        const handleAlignText = (alignment) => {
            execCommand('justify' + alignment);
        };

        const handleColorChange = (e) => {
            execCommand('foreColor', e.target.value);
        };

        const handleBackgroundColorChange = (e) => {
            execCommand('backColor', e.target.value);
        };

// Add a function to handle table insertion:
        const handleInsertTable = () => {
            const rows = prompt('Enter number of rows:', '2');
            const cols = prompt('Enter number of columns:', '2');
            if (rows && cols) {
                let table = '<table class="w-full border-collapse border border-gray-700 my-4">';
                table += '<thead><tr class="bg-gray-800">';
                for (let j = 0; j < cols; j++) {
                    table += '<th class="border border-gray-700 px-4 py-2 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Header</th>';
                }
                table += '</tr></thead><tbody>';
                for (let i = 0; i < rows - 1; i++) {
                    table += '<tr class="' + (i % 2 === 0 ? 'bg-gray-900' : 'bg-gray-800') + '">';
                    for (let j = 0; j < cols; j++) {
                        table += '<td class="border border-gray-700 px-4 py-2 whitespace-normal text-sm text-gray-300">Cell</td>';
                    }
                    table += '</tr>';
                }
                table += '</tbody></table>';
                execCommand('insertHTML', table);
            }
        };

        const handleInsertImage = () => {
            if (savedSelection) {
                const selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(savedSelection);
                if (uploadedImage) {
                    const formData = new FormData();
                    formData.append('image', uploadedImage);
                    formData.append('email', cookies.email);

                    fetch(`${API_BASE_URL}/api/v1/image/upload`, {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${cookies.token}`
                        },
                        body: formData
                    }).then(response => response.json())
                        .then(data => {
                            let img = '<img src="' + API_BASE_URL + data.url + '" class="w-fit h-fit" crossOrigin="anonymous" alt="Uploaded Image">';
                            editorRef.current.focus(); // Ensure the editor is focused
                            document.execCommand('insertHTML', false, img);
                            setError(false);
                        })
                        .catch(error => {
                            setError(true);
                            console.error('Error:', error)
                        });
                    setUploadedImage(null);
                } else if (imageUrl) {
                    let test = '<img src="' + imageUrl + '" crossOrigin="anonymous" class="w-fit h-fit" alt="Uploaded Image">';
                    editorRef.current.focus(); // Ensure the editor is focused
                    document.execCommand('insertHTML', false, test);

                    setImageUrl('');
                }
            }
            setShowImageDialog(false);
            setLinkUrl('');
            setLinkTitle('');
            setSavedSelection(null);
        }

        const handleLinkDialogOpen = () => {
            const selection = window.getSelection();
            if (selection.rangeCount > 0) {
                setSavedSelection(selection.getRangeAt(0));
                setLinkTitle(selection.toString());
            }
            setShowLinkDialog(true);
        };

        const handleImageDialogOpen = () => {
            const selection = window.getSelection();
            if (selection.rangeCount > 0) {
                setSavedSelection(selection.getRangeAt(0));
                setLinkTitle(selection.toString());
            }
            setShowImageDialog(true);
        }


        return (
            <Fragment>
                <div
                    className="z-[90] fixed bottom-0 left-1/2 transform -translate-x-1/2 font-serif overflow-x-auto max-w-full scrollbar-hide">
                    <div
                        className="z-[90] inline-flex items-center border-t border-l border-r rounded-t-2xl p-2 space-x-2 bg-gradient-to-r from-[#121212] to-[#1e1e1e]">
                        <button
                            onClick={() => execCommand('undo')}
                            className={`p-1 rounded ${canUndo ? 'hover:bg-gray-600' : 'opacity-50 cursor-not-allowed'}`}
                            title="Undo"
                            disabled={!canUndo}
                        >
                            <FaUndo/>
                        </button>
                        <button
                            onClick={() => execCommand('redo')}
                            className={`p-1 rounded ${canRedo ? 'hover:bg-gray-600' : 'opacity-50 cursor-not-allowed'}`}
                            title="Redo"
                            disabled={!canRedo}
                        >
                            <FaRedo/>
                        </button>
                        <hr className="border border-gray-600 h-6 mx-2"/>
                        <button onClick={() => execCommand('bold')} className="p-1 hover:bg-gray-600 rounded"
                                title="Bold">
                            <FaBold/>
                        </button>
                        <button onClick={() => execCommand('italic')} className="p-1 hover:bg-gray-600 rounded"
                                title="Italic">
                            <FaItalic/>
                        </button>
                        <button onClick={() => execCommand('underline')} className="p-1 hover:bg-gray-600 rounded"
                                title="Underline">
                            <FaUnderline/>
                        </button>
                        <hr className="border border-gray-600 h-6 mx-4"/>
                        <select
                            onChange={(e) => execCommand('applyFontFamily', e.target.value)}
                            className="p-1 border rounded bg-gray-800 text-white"
                            defaultValue="font-lora"
                        >
                            {fontFamilies.map((font) => (
                                <option key={font.class} value={font.class}>{font.name}</option>
                            ))}
                        </select>
                        <hr className="border border-gray-600 h-6 mx-2"/>
                        <select onChange={(e) => execCommand('fontSize', e.target.value)}
                                className="p-1 border rounded bg-gray-800 text-white"
                                defaultValue="3">
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((size) => (
                                <option key={size} value={size}>{size}</option>
                            ))}
                        </select>
                        <hr className="border border-gray-600 h-6 mx-2"/>
                        <button onClick={() => handleAlignText('Left')} className="p-1 hover:bg-gray-600 rounded"
                                title="Align Left">
                            <FaAlignLeft/>
                        </button>
                        <button onClick={() => handleAlignText('Center')} className="p-1 hover:bg-gray-600 rounded"
                                title="Align Center">
                            <FaAlignCenter/>
                        </button>
                        <button onClick={() => handleAlignText('Right')} className="p-1 hover:bg-gray-600 rounded"
                                title="Align Right">
                            <FaAlignRight/>
                        </button>
                        <hr className="border border-gray-600 h-6 mx-2"/>
                        <button onClick={() => execCommand('insertUnorderedList')}
                                className="p-1 hover:bg-gray-600 rounded"
                                title="Bullet List">
                            <FaListUl/>
                        </button>
                        <button onClick={() => execCommand('insertOrderedList')}
                                className="p-1 hover:bg-gray-600 rounded"
                                title="Numbered List">
                            <FaListOl/>
                        </button>
                        <hr className="border border-gray-600 h-6 mx-2"/>
                        <div onChange={handleColorChange} className="flex-row items-center p-0">
                            <ImTextColor className={"mx-auto -mb-1"}/>

                            <input id={"inputColor"} type="color" className="w-8 h-4 rounded border-none p-0 -mt-2"
                                   title="Text Color"/>
                        </div>
                        <div onChange={handleBackgroundColorChange} className="flex-row items-center p-0">
                            <MdFormatColorFill className={"mx-auto -mb-1"}/>
                            <input id={"inputBgColor"} type="color" className="w-8 h-4 rounded border-none p-0 -mt-2"
                                   title="Background Color"/>
                        </div>
                        <hr className="border border-gray-600 h-6 mx-2"/>
                        <button onClick={handleLinkDialogOpen} className="p-1 hover:bg-gray-600 rounded"
                                title="Insert Link">
                            <FaLink/>
                        </button>
                        <button onClick={handleInsertTable} className="p-1 hover:bg-gray-600 rounded"
                                title="Insert Table">
                            <FaTable/>
                        </button>
                        <button onClick={handleImageDialogOpen} className="p-1 hover:bg-gray-600 rounded"
                                title="Insert Image">
                            <FaImage/>
                        </button>
                        <button onClick={() => execCommand('formatBlock', '<blockquote>')}
                                className="p-1 hover:bg-gray-600 rounded" title="Blockquote">
                            <FaQuoteRight/>
                        </button>
                        <button onClick={() => execCommand('insertCodeBlock')} className="p-1 hover:bg-gray-600 rounded"
                                title="Code Block">
                            <IoCodeSharp/>
                        </button>
                        <hr className="border border-gray-600 h-6 mx-2"/>
                        <button onClick={() => execCommand('insertHorizontalRule')}
                                className="p-1 hover:bg-gray-600 rounded" title="Horizontal Line">
                            <FaMinus/>
                        </button>
                    </div>
                </div>
                <div className="mx-auto pb-10">
                    <div
                        id="content-rich-text"
                        ref={editorRef}
                        className="content-rich-text border border-gray-600 p-4 min-h-[400px] bg-[#171717] mb-8 rounded-2xl overflow-hidden break-words"
                        contentEditable
                        onInput={(e) => {
                            const newContent = e.currentTarget.innerHTML;
                            if (newContent !== content) {
                                setContent(newContent);
                            }
                            setCanUndo(document.queryCommandEnabled('undo'));
                            setCanRedo(document.queryCommandEnabled('redo'));
                        }}

                    />

                    {/*// Add a dialog to insert an image:*/}
                    {showImageDialog && (
                        <div
                            className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
                            <div className="bg-gray-800 p-5 rounded-lg shadow-xl w-96">
                                <h3 className="text-xl font-semibold mb-4 text-white">Insert Image</h3>
                                {error && (
                                    <span className={"text-red-400 text-sm mb-1"}>Upload image smaller 1 MB</span>
                                )}
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => setUploadedImage(e.target.files[0])}
                                    className="w-full p-2 mb-4 border rounded bg-gray-700 text-white"
                                />
                                <input
                                    type="text"
                                    value={imageUrl}
                                    onChange={(e) => setImageUrl(e.target.value)}
                                    placeholder="Or enter image URL"
                                    className="w-full p-2 mb-4 border rounded bg-gray-700 text-white"
                                />
                                <div className="flex justify-end">
                                    <button
                                        onClick={() => setShowImageDialog(false)}
                                        className="px-4 py-2 bg-gray-600 text-white rounded mr-2 hover:bg-gray-500"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={handleInsertImage}
                                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                                    >
                                        Insert
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    {/*// Add a dialog to insert a link:*/}
                    {showLinkDialog && (
                        <div
                            className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
                            <div className="bg-gray-800 p-5 rounded-lg shadow-xl w-96">
                                <h3 className="text-xl font-semibold mb-4 text-white">Insert Link</h3>
                                <input
                                    type="text"
                                    value={linkUrl}
                                    onChange={(e) => setLinkUrl(e.target.value)}
                                    placeholder="Enter URL"
                                    className="w-full p-2 mb-4 border rounded bg-gray-700 text-white"
                                />
                                <input
                                    type="text"
                                    value={linkTitle}
                                    onChange={(e) => setLinkTitle(e.target.value)}
                                    placeholder="Enter link text"
                                    className="w-full p-2 mb-4 border rounded bg-gray-700 text-white"
                                />
                                <div className="flex justify-end">
                                    <button
                                        onClick={() => setShowLinkDialog(false)}
                                        className="px-4 py-2 bg-gray-600 text-white rounded mr-2 hover:bg-gray-500"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={() => execCommand('createLink')}
                                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                                    >
                                        Insert
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Fragment>
        );
    }
;

export default RichTextEditor;