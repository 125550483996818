import React from 'react';
import ImageComponent from "../../utils/ImageComponent";
import {Link} from "react-router-dom";

const RecentItem = ({recentItem}) => {
    return (
        <div className={"right-recent-item"}>
            <ImageComponent className={"right-recent-item-logo mr-2 w-7"} image={recentItem.profileImageUrl} alt={recentItem.displayName}/>
            <Link to={`/user/${recentItem.username}`} className="right-recent-item-title text-[#A1A1AA] my-auto">
                {recentItem.displayName}
            </Link>
        </div>
    );

}

export default RecentItem;