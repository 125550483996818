import React, {useEffect} from 'react'
import ItemComment from "../../utils/ItemComment";
import {API_BASE_URL} from "../../../config";
import {Button} from "@headlessui/react";
import {useNavigate} from "react-router-dom";
import AddComment from "../../utils/AddComment";
import {IoReloadSharp} from "react-icons/io5";
import {TbMoodLookDown} from "react-icons/tb";
import {useCookies} from "../../../cookieContext";

function CommentBlogDetail({blogId}) {
    const [disabled, setDisabled] = React.useState(true)
    const [commentList, setCommentList] = React.useState([])
    const navigation = useNavigate()
    const [userComment, setUserComment] = React.useState('')

    const {cookies} = useCookies()
    const emailLocal = cookies.email
    const tokenLocal = cookies.token
    const [replyComment, setReplyComment] = React.useState({
        status: false,
        commentId: null
    })

    useEffect(() => {
        const fetchComments = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/api/v1/blog/comment/get/${blogId}`)
                const data = await response.json()
                setCommentList(data)

            } catch (error) {
                console.error(error)
            }
        }
        fetchComments()
    }, [])

    const handleDeleteComment = async (commentId) => {
        try {
            const response = await fetch(`${API_BASE_URL}/api/v1/blog/comment/edit/delete/${commentId}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${tokenLocal}`
                }
            });
            if (!response.ok) {
                throw new Error("Error deleting comment");
            }
            setCommentList(commentList.filter(comment => comment.id !== commentId));
        } catch (error) {
            console.error(error);
        }
    }

    const handleInputChange = (e) => {
        setUserComment(e.target.value)
    }

    const handleAddComment = async (parentCommentId) => {
        if (emailLocal === null && tokenLocal === null) {
            navigation("/login")
            return;
        }
        try {
            const request = {
                "blogId": blogId,
                "email": emailLocal,
                "parentCommentId": parentCommentId,
                "content": userComment
            }

            const response = await fetch(`${API_BASE_URL}/api/v1/blog/comment/edit/add`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${tokenLocal}`
                },
                body: JSON.stringify(request)
            })
            if (!response.ok) {
                throw new Error("Error")
            }
            const data = await response.json()
            setCommentList([...commentList, data])
            setUserComment('')
            setReplyComment({
                status: false,
                commentId: data.id
            })
        } catch (error) {
            console.error(error)
        }
    }

    const handleReply = (commentId) => {
        setReplyComment({
            status: true,
            commentId: commentId
        })
        if (replyComment.commentId === commentId && replyComment.status) {
            setReplyComment({
                status: false,
                commentId: null
            })
        }
    }

    const handleLoadComments = () => {
        setDisabled(false)
    }


    return (
        <div className={"w-full mt-9"}>
            <h2 className={"text-xl font-bold"}>{commentList.length} Comments</h2>
            <hr className={"mt-0"}/>

            {/* User comment here */}
            <div className="comment-here mt-4">
                <div
                    className="flex items-center pb-4 pt-2 border-b rounded-t border-gray-600">
                    <AddComment onChange={handleInputChange} onclick={() => handleAddComment(null)}
                                tokenLocal={tokenLocal} value={userComment}/>
                </div>
            </div>

            {/* Load Comments */}
            {
                commentList.length === 0 ? (
                    <h1 className={"text-center text-lg mt-4"}>No comments yet</h1>
                ) : (
                    <div className={`${disabled === false ? 'hidden' : 'w-full my-4 text-center'}`}>
                        <Button onClick={handleLoadComments}
                                className="inline-flex items-center gap-1 my-4 rounded-md bg-gray-700 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white">
                            <IoReloadSharp/> Load Comments
                        </Button>
                    </div>
                )
            }

            {/* Comment Item */}
            {
                disabled ? null : (
                    <div className="px-2 pb-2 pt-4 space-y-3">
                        {
                            commentList
                                .filter(comment => !comment.blogPostComment) // Only top-level comments
                                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                                .map((item) => (
                                    <div key={item.id} className={"parent-comment w-full"}>
                                        {
                                            item.status === "DELETED" ? (
                                                <div
                                                    className="flex items-center justify-center text-center bg-[#A1A1AA10] p-4 rounded-lg gap-1 text-sm text-[#A1A1AA98] h-full">
                                                    <TbMoodLookDown/> This comment has been removed.
                                                </div>
                                            ) : (
                                                <ItemComment
                                                    handleDelete={() => handleDeleteComment(item.id)}
                                                    name={item.user.displayName}
                                                    content={item.content}
                                                    updateAt={item.updatedAt}
                                                    imageUser={item.user.profileImageUrl}
                                                    commentUser={item.user.email}
                                                    username={item.user.username}
                                                    handleReply={() => handleReply(item.id)}
                                                />
                                            )
                                        }

                                        {commentList
                                            .filter(comment => comment.blogPostComment?.id === item.id)
                                            .map(childComment => (
                                                <div key={childComment.id}
                                                     className="child-comment pt-2 ml-1 pl-6 border-l border-[#A1A1AA40]">
                                                    {
                                                        childComment.status === "DELETED" ? (
                                                            <div
                                                                className="flex items-center justify-center text-center bg-[#A1A1AA10] p-4 rounded-lg gap-1 text-sm text-[#A1A1AA98] h-full">
                                                                <TbMoodLookDown/> This comment has been removed.
                                                            </div>

                                                        ) : (
                                                            <ItemComment
                                                                handleDelete={() => handleDeleteComment(childComment.id)}
                                                                name={childComment.user.displayName}
                                                                content={childComment.content}
                                                                updateAt={childComment.updatedAt}
                                                                imageUser={childComment.user.profileImageUrl}
                                                                commentUser={childComment.user.email}
                                                                username={childComment.user.username}
                                                                handleReply={() => handleReply(item.id)}
                                                            />
                                                        )
                                                    }

                                                </div>
                                            ))
                                        }
                                        <div
                                            className={replyComment.commentId === item.id && replyComment.status ? "child-comment pt-2 ml-1 pl-6 border-l border-[#A1A1AA40]" : "hidden"}>
                                            <AddComment
                                                className={replyComment.commentId === item.id && replyComment.status ? 'mt-3' : 'hidden'}
                                                onChange={handleInputChange}
                                                onclick={() => handleAddComment(item.id)}
                                                tokenLocal={tokenLocal}
                                            />
                                        </div>
                                    </div>
                                ))
                        }
                    </div>
                )
            }
        </div>
    )
}

export default CommentBlogDetail
