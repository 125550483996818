import CryptoJS from 'crypto-js';

let SECRET_KEY;

if (typeof window !== 'undefined') {
    // Client-side
    SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
} else {
    // Server-side
    SECRET_KEY = 'my-secret-key';
}


if (!SECRET_KEY) {
    console.error('SECRET_KEY is not set in environment variables');
}

export const encodeAPIKey = (data) => {
    try {
        return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
    } catch (error) {
        console.error('Encryption error:', error);
        return null;
    }
};

export const decodeAPIKey = (encryptedData) => {
    try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (error) {
        console.error('Decryption error:', error);
        return null;
    }
};