import React, {Fragment, useEffect} from 'react';
import MenuComponent from "../../utils/menu/MenuComponent";
import {BsFileRichtext} from "react-icons/bs";
import {FaCode} from "react-icons/fa";
import {Link, useNavigate} from "react-router-dom";
import {useCookies} from "../../../cookieContext";
import StructuredData from "../../utils/StructuredData";

const SelectHowCreateNewBlogComponent = () => {
    const {cookies} = useCookies();
    const navigate = useNavigate()
    useEffect(() => {
        if (cookies.token === null && cookies.username === null) {
            navigate('/login')
        }
    })

    const accountStructuredData = {
        "@context": "https://schema.org",
        "@type": "ProfilePage",
        "url": `https://w404.net/blog/types`,
        "name": `Select How to Create Blog`,
        "description": `Select how to create a blog`,
        "image": "https://w404.net/assets/images/background.png"
    };

    return (
        <Fragment>
            <MenuComponent/>
        <div className={"container"}>
            <StructuredData imageUrl="https://w404.net/assets/images/background.png"
                            data={accountStructuredData} title={"Select How to Create Blog"} url={`https://w404.net/blog/types`}
                            description={"Select How to Create Blog"}/>

            <div className="mt-[6%] m-auto h-screen">
                <div className="text-[#F4F4F5] p-8 text-center px-3">
                    <h2 className="text-2xl font-bold mb-2">Choose the best way to create your blog </h2>
                    <p className="text-gray-400 mb-6">
                        You can choose between using a Markdown Editor or uploading HTML and CSS code to create your
                        blog.
                    </p>

                    <div className="mt-12 m-auto flex justify-center items-center">
                        <Link to={"/blog/new"} state={{editorType: 'markdown'}}
                              className="bg-gray-800 py-5 px-12 mr-4 rounded-lg flex flex-col items-center hover:border-2 hover:border-[#6665F1] duration-400 ease-in-out">
                            <div className="text-[#6665F1] text-3xl mb-2">
                                <BsFileRichtext/>
                            </div>
                            <div className="text-[#6665F1]  font-semibold">Markdown Editor</div>
                            <div className="text-sm text-gray-400">Simple</div>
                        </Link>

                        <Link to={"/blog/new"} state={{editorType: 'code'}}
                              className="bg-gray-800 py-5 px-12 rounded-lg flex flex-col items-center hover:border-2 hover:border-[#A556F6] duration-400 ease-in-out">
                            <div className="text-[#A556F6] text-3xl mb-2">
                                <FaCode/>
                            </div>
                            <div className="text-[#A556F6] font-semibold">
                                HTML-CSS
                            </div>
                            <div className="text-sm text-gray-400">
                                    <span
                                        className={"px-1 mt-2 mr-1 py-0 bg-blue-500 text-[#F4F4F5] text-[13px] h-fit rounded"}>
                                        Beta
                                    </span>
                                Advanced
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
        </Fragment>
    );

}

export default SelectHowCreateNewBlogComponent;