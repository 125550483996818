import React, {useState, useEffect} from 'react';
import {GoogleLogin, GoogleOAuthProvider} from "@react-oauth/google";
import {jwtDecode} from "jwt-decode";
import OTPVerification from "./OTPVerification";
import Spinner from "../utils/sipnner/Spinner";
import {API_BASE_URL} from "../../config";
import {Link} from "react-router-dom";
import bcrypt from 'bcryptjs';


const GoogleLoginComponent = () => {
    const [rainDrops, setRainDrops] = useState([]);
    const [showOTPVerification, setShowOTPVerification] = useState(false);
    const [userInformation, setUserInformation] = useState({});
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        emailLogin: '',
        passwordLogin: ''
    });
    const [formErrors, setFormErrors] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);


    const handleClearForm = () => {
        setFormData({name: '', email: '', password: '', confirmPassword: '', emailLogin: '', passwordLogin: ''});
        setFormErrors({});
        setIsFormValid(false);
    }

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData(prev => ({...prev, [name]: value}));
        if (name === 'emailLogin' || name === 'passwordLogin') {
            validateFormLogin({...formData, [name]: value});
        } else {
            validateForm({...formData, [name]: value});
        }
    };

    const validateForm = (data) => {
        let errors = {};
        if (data.name.length < 6 || data.name.length > 150) errors.name = "Name must be between 6 and 150 characters";
        if (!/\S+@\S+\.\S+/.test(data.email)) errors.email = "Email is invalid";
        if (data.password.length < 6) {
            errors.password = "Password must be 7-22 characters and include letters";
        } else if (data.password.length > 50) {
            errors.password = "Your password is too long";
        }
        if (data.password !== data.confirmPassword) errors.confirmPassword = "Passwords do not match";

        setFormErrors(errors);
        setIsFormValid(Object.keys(errors).length === 0);
    };

    const validateFormLogin = (data) => {
        let errors = {};

        if (!/\S+@\S+\.\S+/.test(data.emailLogin)) errors.emailLogin = "Email is invalid";

        if (data.passwordLogin.length < 6) {
            errors.passwordLogin = "Password must be at least 6 characters";
        }

        setFormErrors(errors);
        setIsFormValid(Object.keys(errors).length === 0);
    }

    const generateRandomId = (length) => {
        const characters = '0123456789';
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (isFormValid) {
            try {
                const dataToSend = {
                    email: formData.emailLogin,
                    password: formData.passwordLogin, // Send plaintext password
                };

                const response = await fetch(`${API_BASE_URL}/api/auth/login`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(dataToSend)
                });

                if (!response.ok) {
                    setFormErrors({notifyLogin: 'Invalid email or password'});
                }

                const data = await response.json();

                if (data.otpCode) {
                    setUserInformation(data);
                    setShowOTPVerification(true);
                }

            } catch (error) {

            } finally {
                setLoading(false);
            }
        }
    }

    const handleRegister = async (e) => {
        e.preventDefault();
        if (isFormValid) {
            try {
                const salt = await bcrypt.genSalt(10);
                const hashedPassword = await bcrypt.hash(formData.password, salt);

                const dataToSend = {
                    name: formData.name,
                    googleId: generateRandomId(26),
                    email: formData.email,
                    imageUrl: `https://api.dicebear.com/9.x/pixel-art/svg?seed=${formData.name}`,
                    password: hashedPassword,
                };

                const response = await fetch(`${API_BASE_URL}/api/auth/signup`, {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify(dataToSend)
                });

                if (!response.ok) {
                    setFormErrors({notifyRegister: 'Email already exists, login first or use another email'});
                }

            } catch (error) {
                console.error('Error during signup:', error);
            } finally {
                window.location.reload();
            }
        }
    };

    const handleGoogleLoginSuccess = async (response) => {
        setLoading(true);
        try {
            const detail = jwtDecode(response.credential)
            // Google login successful, now send user info to your backend
            const userInfo = {
                googleId: detail.sub,
                email: detail.email,
                name: detail.name,
                imageUrl: detail.picture,
                token: response.credential // This is the ID token from Google
            };

            // Send user info to your backend
            const backendResponse = await fetch(API_BASE_URL + '/api/auth/google-login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userInfo)
            });

            const data = await backendResponse.json();

            if (data.otpCode) {
                setUserInformation(data);
                setShowOTPVerification(true);
            }
        } catch (error) {
            console.error('Error during Google login:', error);
        } finally {
            setLoading(false);
        }
    };


    // Rain Alphabet Animation
    useEffect(() => {
        const createRainDrop = () => {
            const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
            return {
                x: Math.random() * (typeof window !== 'undefined' && window.innerWidth),
                y: -20,
                character: alphabet[Math.floor(Math.random() * alphabet.length)],
                speed: 1 + Math.random() * 3,
                opacity: 0.1 + Math.random() * 0.5,
            };
        };

        const animateRain = () => {
            setRainDrops((prevDrops) => {
                const newDrops = prevDrops.map((drop) => ({
                    ...drop,
                    y: drop.y + drop.speed,
                })).filter((drop) => drop.y < window.innerHeight);

                while (newDrops.length < 50) {
                    newDrops.push(createRainDrop());
                }

                return newDrops;
            });
        };

        const rainInterval = setInterval(animateRain, 50);

        return () => clearInterval(rainInterval);
    }, []);


    const renderLoginButton = () => {
        return (
            <div className="w-fit m-auto items-center">
                <GoogleOAuthProvider
                    clientId="231221320612-t4bp6bis7hkvkd0fikvv10shuibmqsun.apps.googleusercontent.com">
                    {/*// clientId="231221320612-9bk0jghlqv75ijh8p02i5aabs65sjc7b.apps.googleusercontent.com">*/}
                    <GoogleLogin
                        text={"signin_with"}
                        shape={"pill"}
                        size={"large"}
                        logo_alignment={"center"}
                        width={"300"}
                        ux_mode={"popup"}
                        onSuccess={handleGoogleLoginSuccess}
                        onError={() => {
                            window.location.reload();
                            console.error('Login Failed');
                        }}
                    />
                </GoogleOAuthProvider>
            </div>
        )
    }

    return (
        <div
            className="relative flex justify-center items-center h-screen overflow-hidden mx-1">            {/* Rain Alphabet Background */}
            {rainDrops.map((drop, index) => (
                <div
                    key={index}
                    className="absolute text-purple-500 pointer-events-none"
                    style={{
                        left: `${drop.x}px`,
                        top: `${drop.y}px`,
                        opacity: drop.opacity,
                        fontSize: '14px',
                        fontWeight: 'bold',
                        textShadow: '0 0 5px rgba(165, 86, 246, 0.7)',
                    }}
                >
                    {drop.character}
                </div>
            ))}

            {showOTPVerification ? (<OTPVerification userInformation={userInformation}/>)
                : loading ? (<Spinner/>) : (
                    <div className="container">
                        <input type="checkbox" id="signup_toggle"/>

                        <div className="form">
                            <div className="form_front flipped">
                                <div className="form_details">Login</div>
                                <input name={"emailLogin"} value={formData.emailLogin} onChange={handleInputChange}
                                       placeholder="Email" className="input" type="email"/>
                                {formErrors.emailLogin &&
                                    <span className="text-red-400 error text-sm">{formErrors.emailLogin}</span>}
                                <input name={"passwordLogin"} value={formData.passwordLogin}
                                       onChange={handleInputChange}
                                       placeholder="Password" className="input" type="password"/>
                                {formErrors.passwordLogin &&
                                    <span className="text-red-400 error text-sm">{formErrors.passwordLogin}</span>}
                                {formErrors.notifyLogin &&
                                    <span className="text-red-400 error text-sm">{formErrors.notifyLogin}</span>}

                                <button onClick={handleLogin} disabled={!isFormValid} className="btn">Login</button>
                                <span className="switch">
                                  <Link to={"/forgot-password"} className="forgot_password underline">Forgot Password?</Link>
                                  <span className={"ml-2 mr-1"}>or</span>
                                  <label onClick={handleClearForm} className="signup_tog ml-1" htmlFor="signup_toggle">
                                    Sign Up
                                  </label>
                                </span>
                                <div className="flex items-center space-x-2 w-full -mt-4 -mb-3">
                                    <hr className="flex-grow border-zinc-700"/>
                                    <span className="text-zinc-300 text-[12px]">OR</span>
                                    <hr className="flex-grow border-zinc-700"/>
                                </div>
                                <div className="">
                                    {renderLoginButton()}
                                </div>
                            </div>
                            <div className="form_back">
                                <div className="form_details">SignUp</div>
                                <input name="name" value={formData.name} onChange={handleInputChange} min={6} max={150}
                                       placeholder="Your Name" className="input" type="text"/>
                                {formErrors.name &&
                                    <span className="text-red-400 error text-sm">{formErrors.name}</span>}
                                <input name="email" value={formData.email} onChange={handleInputChange}
                                       placeholder="Email" className="input" type="email"/>
                                {formErrors.email &&
                                    <span className="text-red-400 error text-sm">{formErrors.email}</span>}
                                <input name="password" value={formData.password} onChange={handleInputChange}
                                       placeholder="Password" className="input" type="password"/>
                                {formErrors.password &&
                                    <span className="text-red-400 error text-sm">{formErrors.password}</span>}
                                <input
                                    name="confirmPassword"
                                    value={formData.confirmPassword}
                                    onChange={handleInputChange}
                                    placeholder="Confirm Password"
                                    className="input"
                                    type="password"
                                />
                                {formErrors.confirmPassword &&
                                    <span className="text-red-400 error text-sm">{formErrors.confirmPassword}</span>}
                                {formErrors.notifyRegister &&
                                    <span className="text-red-400 error text-sm">{formErrors.notifyRegister}</span>}
                                <button className="btn" onClick={handleRegister} disabled={!isFormValid}>Signup</button>
                                <span className="switch">
                                  Already have an account?
                                  <label onClick={handleClearForm} className="signup_tog ml-1" htmlFor="signup_toggle">
                                    Sign In
                                  </label>
                                </span>
                                <div className="flex items-center space-x-2 w-full -mtq-4 -mb-3">
                                    <hr className="flex-grow border-zinc-700"/>
                                    <span className="text-zinc-300 text-[12px]">OR</span>
                                    <hr className="flex-grow border-zinc-700"/>
                                </div>
                                <div className="">
                                    {renderLoginButton()}
                                </div>
                                <span className="text-xs text-center text-[#A1A1AA]">
                                    By signing in, you agree to our{' '}
                                    <Link
                                        to="/privacy"
                                        className="text-purple-400 hover:text-purple-300 underline"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                Privacy Policy
                            </Link>
                                </span>
                            </div>

                        </div>
                    </div>
                )}
        </div>
    );
};

export default GoogleLoginComponent;