import React, { useState, useEffect, useCallback, useRef } from 'react';

const IframeComponent = ({ playgroundDetail }) => {
    const [iframeHeight, setIframeHeight] = useState('700px');
    const iframeRef = useRef(null);


    const handleResize = useCallback(() => {
        setIframeHeight(typeof window !== 'undefined' && window.innerWidth < 768 ? '400px' : '700px');
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, [handleResize]);

    return (
        <div className="detail-body col-span-1 h-fit rounded-xl overflow-hidden mt-4">
            <iframe
                ref={iframeRef}
                src={playgroundDetail.link}
                title={playgroundDetail.title}
                allowFullScreen={false}
                width="100%"
                height={iframeHeight}
            />
        </div>
    );
};

export default IframeComponent;
