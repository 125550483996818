import React from 'react'

export default function PrivacyComponent() {
    return (
        <div className="bg-[#121212] text-[#F4F4F5]">
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-3xl font-bold mb-6">Terms & Privacy Policy</h1>
                <p className="mb-4">We are committed to protecting your privacy and ensuring a safe, open platform for
                    sharing.</p>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-2">Data Protection</h2>
                    <p>We do not sell your personal data or account information. Your privacy is our top priority, and
                        we implement stringent measures to safeguard your data.</p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-2">Secure Storage</h2>
                    <p>All your information is securely stored on our servers using industry-standard encryption
                        protocols. We regularly update our security measures to ensure data integrity and
                        protection.</p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-2">Google User Data</h2>
                    <p>We use your Google data, such as your name, avatar, and email, to create your account. This data
                        is accessed with your explicit consent and is used solely for account creation purposes. We do
                        not share your Google user data with third parties without your explicit permission.</p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-2">Data Usage</h2>
                    <p>We collect and use your data to enhance your experience, provide support, and improve our
                        services. Detailed information on how we collect, use, and share your data can be provided upon
                        request.</p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-2">Freedom of Expression</h2>
                    <p>We support free expression. You can write and share any content, including NSFW material, as long
                        as it complies with applicable laws and our community guidelines.</p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-2">Email Communications</h2>
                    <p>We respect your inbox and hate spam as much as you do. To protect your privacy and prevent
                        unwanted communications, we limit the number of emails we send. You will only receive important
                        notifications, updates, and responses to your inquiries. You can manage your email preferences
                        in your account settings at any time.</p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-2">AI Model API Keys</h2>
                    <p>Your API keys for AI models are treated with the utmost security. We employ robust encryption
                        techniques to encode your API keys before storing them in our database. These keys are only
                        decoded when you actively use them. We want to assure you that WE DO NOT SHARE OR USE YOUR
                        API KEYS for any purpose other than facilitating your intended interactions with the AI models.
                        Your trust and the security of your sensitive information are paramount to us.</p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-2">User Responsibility</h2>
                    <p>You are responsible for the content you share. We encourage respectful and lawful use of our
                        platform. Violation of our terms may result in account suspension or termination.</p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-2">Additional Information</h2>
                    <ul className="list-disc pl-5">
                        <li className="mb-2">We may update this policy periodically. Check back regularly for any
                            changes.
                        </li>
                        <li className="mb-2">We use cookies to enhance your browsing experience and analyze site
                            traffic.
                        </li>
                        <li className="mb-2">You can request access to your personal data or ask for its deletion by
                            contacting us.
                        </li>
                        <li className="mb-2">We comply with relevant data protection laws and regulations.</li>
                        <li className="mb-2">We will block your account if you attack other users with scripts or engage
                            in any similar malicious activities.
                        </li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-2">Acceptance of this Policy</h2>
                    <p>You acknowledge that you have read this Policy and agree to all its terms and conditions. By
                        using the Website, you agree to be bound by this Policy. If you do not agree to abide by the
                        terms of this Policy, you are not authorized to use or access the Website.</p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-2">Contacting Us</h2>
                    <p>If you have any questions about this Policy, please contact us by email at: <a
                        href="mailto:support@w404.net" className="text-blue-500 underline">support@w404.net</a></p>
                </section>
            </div>
        </div>
    )
}
