import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/react'
import {RiArrowDropDownLine} from "react-icons/ri";
import {Link, useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import {FiPlus} from "react-icons/fi";
import {GoSignOut} from "react-icons/go";
import {MdOutlineAccountCircle} from "react-icons/md";
import {googleLogout} from "@react-oauth/google";
import ImageComponent from "../ImageComponent";
import {useCookies} from "../../../cookieContext";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function MenuUserDropdown() {
    const navigate = useNavigate();
    const {cookies, removeCookie} = useCookies()

    const email = cookies.email
    const profileImageUrl = cookies.profileImageUrl
    const username = cookies.username
    const verified = cookies.verified

    const signOut = () => {
        googleLogout()
        removeCookie('token')
        removeCookie('email')
        removeCookie('profileImageUrl')
        removeCookie('username')
        removeCookie('verified')
        navigate('/login')
    }

    useEffect(() => {
        if (!cookies.token || !cookies.email || !cookies.profileImageUrl || !cookies.username) {
            googleLogout()
            removeCookie('token')
            removeCookie('email')
            removeCookie('profileImageUrl')
            removeCookie('username')
            removeCookie('verified')
            navigate('/login')
        }
    }, [cookies, removeCookie, navigate])

    return (
        <Menu as="div" className="relative inline-block text-left z-50">
            <div>
                <MenuButton
                    className="items-center inline-flex w-full justify-center gap-x-1.5 text-sm">
                    <ImageComponent image={profileImageUrl} className={"h-9 w-9"} alt={username}/>
                    <RiArrowDropDownLine className={"-mr-1 h-5 w-5 text-gray-400"}/>
                </MenuButton>
            </div>

            <MenuItems
                transition
                className="absolute right-0 z-90 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-lg bg-[#1A1A1D] shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
                <div className="px-4 py-3">
                    <p className="text-sm text-[#A1A1AA]">Signed in as</p>
                    <p className="truncate text-sm font-medium text-[#F4F4F5]">{email}</p>
                </div>
                <div className="py-1">
                    <MenuItem>
                        {({focus}) => (
                            <Link to={`/user/${username}`}
                                  className={classNames(focus ? 'bg-gray-100 text-gray-900' : 'text-[#A1A1AA]', 'flex items-center px-4 py-2 text-sm duration-300')}
                            >
                                <MdOutlineAccountCircle className={"mr-2"}/>
                                Account settings
                            </Link>
                        )}
                    </MenuItem>
                    <MenuItem>
                        {({focus}) => (
                            verified ? (
                                <Link to="/explore/new"
                                      className={classNames(focus ? 'bg-gray-100 text-gray-900' : 'text-[#A1A1AA]', 'flex items-center px-4 py-2 text-sm duration-300')}
                                >
                                    <FiPlus className={"mr-2"}/>
                                    New Explore
                                </Link>
                            ) : (
                                <span
                                    className={"flex items-center px-4 py-2 text-sm duration-300 cursor-not-allowed text-[#A1A1AA]"}>
                                    <FiPlus className={"mr-2"}/>
                                    New Explore
                                </span>
                            )
                        )}
                    </MenuItem>
                    <MenuItem>
                        {({focus}) => (
                            verified ? (
                                <Link to="/blog/types"
                                      className={classNames(focus ? 'bg-gray-100 text-gray-900' : 'text-[#A1A1AA]', 'flex items-center px-4 py-2 text-sm duration-300')}
                                >
                                    <FiPlus className={"mr-2"}/>
                                    New Blog
                                </Link>
                            ) : (
                                <span
                                    className={"flex items-center px-4 py-2 text-sm duration-300 cursor-not-allowed text-[#A1A1AA]"}>
                                        <FiPlus className={"mr-2"}/>
                                    New Blog
                                </span>
                            )
                        )}
                    </MenuItem>
                </div>
                <div className="py-1">
                    <form method="POST" action="#">
                        <MenuItem>
                            {({focus}) => (
                                <button onClick={signOut}
                                        type="submit"
                                        className={classNames(
                                            focus ? 'bg-gray-100 text-gray-900' : 'text-[#A1A1AA]',
                                            'flex items-center w-full px-4 py-2 text-left text-sm duration-300',
                                        )}
                                >
                                    <GoSignOut className={"mr-2"}/>
                                    Sign out
                                </button>
                            )}
                        </MenuItem>
                    </form>
                </div>
            </MenuItems>
        </Menu>
    )
}
