import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import GoogleLoginComponent from "./GoogleLoginComponent";
import MenuComponent from "../utils/menu/MenuComponent";
import Spinner from "../utils/sipnner/Spinner";
import {API_BASE_URL} from "../../config";
import {useCookies} from "../../cookieContext";
import StructuredData from "../utils/StructuredData";
import './Login.css';

const LoginComponent = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const {cookies, setCookie, removeCookie} = useCookies();


    useEffect(() => {
        const verifyToken = async () => {
            const token = cookies.token;
            if (!token) return;

            try {
                setLoading(true);
                const response = await fetch(API_BASE_URL + '/api/auth/verify', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({token})
                });

                if (response.ok) {
                    setCookie('token', token);
                    navigate('/');
                } else {
                    navigate('/login');
                    ['token', 'verified', 'profileImageUrl', 'email', 'username'].forEach(item =>
                        removeCookie(item)
                    );
                }
            } catch (error) {
                setLoading(false);
                console.error('Token verification error:', error);
            }
        };

        void verifyToken();
    }, [navigate]);

    const structuredData = {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "url": "https://w404.net/login",
        "name": "W404 Login",
        "description": "Login to W404 World using your Google account to create and share amazing things without limits.",
        "image": "https://w404.net/assets/images/background.png",
        "publisher": {
            "@type": "Organization",
            "name": "W404",
            "logo": {
                "@type": "ImageObject",
                "url": "https://w404.net/assets/images/logo1.png"
            }
        },
        "potentialAction": {
            "@type": "LoginAction",
            "target": "https://w404.net/login"
        },
        "sameAs": [
            "https://www.facebook.com/w404.net",
        ]
    };

    return (
        <div className={"max-h-screen overflow-hidden"}>
            <StructuredData imageUrl="https://w404.net/assets/images/background.png"
                            data={structuredData} title={"W404 Login"} url={"https://w404.net/login"}
                            description={"Login to W404 World using your Google account to create and share amazing things without limits."}/>
            <MenuComponent/>
            <div className={"container"}>
                {loading ? <Spinner/> : <GoogleLoginComponent/>}
            </div>
        </div>
    );
}

export default LoginComponent;