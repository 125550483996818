import React from 'react';
import {hydrateRoot} from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {CookieProvider} from "./cookieContext";
import {HelmetProvider} from "react-helmet-async";
import {AppRoutes} from './AppRoutes';

const appRoot = document.getElementById('root');
const initialCookies = window.__INITIAL_COOKIES__ || {};
const initialData = window.__INITIAL_DATA__ || {};

hydrateRoot(
    appRoot,
    <React.StrictMode>
        <HelmetProvider>
            <BrowserRouter>
                <CookieProvider initialCookies={initialCookies}>
                    <AppRoutes initialData={initialData}/>
                </CookieProvider>
            </BrowserRouter>
        </HelmetProvider>
    </React.StrictMode>
);

reportWebVitals();
