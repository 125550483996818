import React from 'react';
import './App.css';
import MenuComponent from "./utils/menu/MenuComponent";
import {motion, useScroll, useTransform} from "framer-motion";
import FooterComponent from "./utils/footer/FooterComponent";
import {Fragment, useRef} from "react";
import {GoogleGeminiEffect} from "../ui/google-gemini-effect";
import TextReveal from "../ui/text-reveal";
import {TypewriterEffect} from "../ui/typewriter-effect";
import TermsAndPrivacyPolicy from "./utils/termsAndPrivacyPolicy";
import StructuredData from "./utils/StructuredData";
import {MarqueeReviewCard} from "./utils/MarqueeReviewCard";

function App() {
    const ref = useRef(null);
    const {scrollYProgress} = useScroll({
        target: ref,
        offset: ["start start", "end start"],
    });

    const words = [
        {
            text: "Create",
        },
        {
            text: "amazing",
        },
        {
            text: "blogs",
        },
        {
            text: "or"
        },
        {
            text: "share"
        },
        {
            text: "amazing"
        },
        {
            text: "things",
        },
        {
            text: "with",
        },
        {
            text: "Everyone.",
            className: "text-[#A556F6]",
        },
    ];

    const pathLengthFirst = useTransform(scrollYProgress, [0, 0.8], [0.2, 1.2]);
    const pathLengthSecond = useTransform(scrollYProgress, [0, 0.8], [0.15, 1.2]);
    const pathLengthThird = useTransform(scrollYProgress, [0, 0.8], [0.1, 1.2]);
    const pathLengthFourth = useTransform(scrollYProgress, [0, 0.8], [0.05, 1.2]);
    const pathLengthFifth = useTransform(scrollYProgress, [0, 0.8], [0, 1.2]);

    const structuredData = {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "url": "https://w404.net",
        "name": "W404 - Share the Awesome",
        "description": "Welcome to W404 World, where boundless creativity meets limitless sharing. Here, you can craft extraordinary creations, pushing the boundaries of imagination. Share your masterpieces freely, inspiring a global community. No restrictions hold you back in this digital playground of infinite possibilities.",
        "publisher": {
            "@type": "Organization",
            "name": "W404",
            "logo": {
                "@type": "ImageObject",
                "url": "https://w404.net/assets/images/logo1.png"
            }
        },
        "potentialAction": {
            "@type": "Action",
            "name": "View",
            "target": "https://w404.net/"
        },
        "sameAs": [
            "https://www.facebook.com/w404.net",
            ],
        "mainEntity": {
            "@type": "Organization",
            "name": "W404 - Share the Awesome",
            "url": "https://w404.net",
            "logo": "https://w404.net/assets/images/logo1.png",
            "contactPoint": {
                "@type": "ContactPoint",
                "contactType": "customer service",
                "email": "support@w404.net",
                "availableLanguage": ["English", "Vietnamese"]
            }
        }
    };

    return (
        <Fragment>
            <StructuredData imageUrl="https://w404.net/assets/images/background.png"
                            data={structuredData} title={"W404 - Share the Awesome"} url={"https://w404.net/"}
                            description={"Welcome to W404 World, where boundless creativity meets limitless sharing. Here, you can craft extraordinary creations, pushing the boundaries of imagination. Share your masterpieces freely, inspiring a global community. No restrictions hold you back in this digital playground of infinite possibilities."}/>
            <MenuComponent/>
            <div
                className="h-[400vh] w-full dark:border dark:border-white/[0.1] rounded-md relative overflow-clip"
                ref={ref}
            >
                <GoogleGeminiEffect
                    title={"Welcome to W404 World"}
                    description={"Where you can ultimately create amazing things and share them with everyone without limits."}
                    pathLengths={[
                        pathLengthFirst,
                        pathLengthSecond,
                        pathLengthThird,
                        pathLengthFourth,
                        pathLengthFifth,
                    ]}
                />
            </div>
            <div className="container">
                <div
                    className="z-10 flex min-h-[16rem] items-center justify-center">
                    <TextReveal text="Express new ideas and create amazing things."/>
                </div>

                <TermsAndPrivacyPolicy/>

                <MarqueeReviewCard/>

                <div className="flex flex-col items-center justify-center h-[50rem] ">
                    <p className="text-neutral-200 text-base  mb-10">
                        The road to freedom starts from here
                    </p>
                    <TypewriterEffect words={words}/>
                    <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 space-x-0 md:space-x-4 mt-10">
                        <button className="w-40 h-10 rounded-xl bg-white text-black border border-black  text-sm">
                            <a href="/login">Signup</a>
                        </button>
                    </div>
                </div>
            </div>
            <FooterComponent/>
        </Fragment>
    );
}

export default App;