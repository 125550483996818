import React, {Fragment, useState} from 'react';
import MenuComponent from "../utils/menu/MenuComponent";
import {Input} from "@headlessui/react";
import {API_BASE_URL} from "../../config";
import Spinner2 from "../utils/sipnner/Spinner2";
import StructuredData from "../utils/StructuredData";

function RequestChangePassword() {
    const [email, setEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleRequestChangePassword = async () => {
        try {
            setIsLoading(true);
            const response = await fetch(`${API_BASE_URL}/api/auth/request-change-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({email: email})
            });

            if (!response.ok) {
                setError('We could not find an account with that email address');
                setSuccess('');
            } else {
                setSuccess('We have sent you an email with a link to reset your password');
                setError('');
            }
        } catch (e) {
            setError('We could not find an account with that email address');
            setSuccess('');
        } finally {
            setIsLoading(false);
        }
    }

    const handleEmailChange = (e) => {
        const emailValue = e.target.value;
        setEmail(emailValue);
        setIsValidEmail(validateEmail(emailValue));
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const structuredData = {
        "@context": "https://schema.org",
        "@type": "ProfilePage",
        "name": "Change Password",
        "description": "Change your password",
        "image": "https://w404.net/assets/images/background.png",
    };


    return (
        <div>
            <StructuredData imageUrl="https://w404.net/assets/images/background.png"
                            data={structuredData} title="Change Password" url={"https://w404.net/forgot-password"}
                            description={"Change Your password"}/>
            <MenuComponent/>
            <div className="min-h-[80svh] flex items-center justify-center bg-[#121212] p-4">
                <div className="max-w-md w-full space-y-8 bg-[#1E1E1E] p-8 rounded-xl shadow-lg">
                    {
                        isLoading ? <Spinner2/> : (
                            <div className="text-center">
                                {
                                    success ? (
                                        <p className="my-6 text-sm text-gray-400">{success}</p>
                                    ) : (
                                        <Fragment>
                                            <h2 className="mt-6 text-3xl font-extrabold text-white">
                                                Change Your Password
                                            </h2>
                                            <p className="mt-6 text-sm text-gray-400">
                                               We will send you a link to reset your
                                                password. Ensure you open this link in the same browser where you are currently logged in or where you browser your requested change password.
                                            </p>
                                            <Input
                                                className={
                                                    'mt-6 py-2 block w-full rounded-lg border-none bg-white/5 px-3 text-sm/6 text-white ' +
                                                    'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                                                }
                                                placeholder={'Enter your email'}
                                                type={'email'}
                                                value={email}
                                                onChange={handleEmailChange}
                                            />
                                            {error && <p className="mt-2 text-red-500 text-sm">{error}</p>}

                                            <button
                                                onClick={handleRequestChangePassword}
                                                className={"my-6 w-full py-2 border rounded-xl text-white hover:bg-white hover:text-black transform duration-300 ease-in-out hover:shadow"}
                                                disabled={!isValidEmail}
                                            >
                                                Change Password
                                            </button>
                                        </Fragment>
                                    )
                                }
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default RequestChangePassword;