import { useEffect } from 'react';

export const useCopyCode = () => {
    useEffect(() => {
        const copyCode = (code) => {
            if (navigator.clipboard && navigator.clipboard.writeText) {
                // Use the modern Clipboard API if available
                navigator.clipboard.writeText(code.textContent)
                    .then(() => {
                        console.log('Code copied to clipboard!');
                        // You can add a visual feedback here instead of an alert
                    })
                    .catch((err) => {
                        console.error('Failed to copy: ', err);
                    });
            } else {
                // Fallback to the older selection method
                const range = document.createRange();
                range.selectNode(code);
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);
                try {
                    document.execCommand('copy');
                    window.getSelection().removeAllRanges();
                    console.log('Code copied to clipboard!');
                    // You can add a visual feedback here instead of an alert
                } catch (err) {
                    console.error('Failed to copy: ', err);
                }
            }
        };

        // Add event listener for copy button clicks
        const handleCopyClick = (event) => {
            const button = event.target.closest('button');
            if (button && button.closest('.relative')) {
                const codeBlock = button.closest('.relative').querySelector('code');
                if (codeBlock) {
                    copyCode(codeBlock);
                }
            }
        };

        document.addEventListener('click', handleCopyClick);

        // Cleanup function
        return () => {
            document.removeEventListener('click', handleCopyClick);
        };
    }, []);
};