import React from 'react';
import {IoClose} from "react-icons/io5";
import {getRelativeTime} from "./dateUtils";
import {FiMessageSquare} from "react-icons/fi";
import ImageComponent from "./ImageComponent";
import {Link} from "react-router-dom";
import {useCookies} from "../../cookieContext";

const CommentItem = ({className, name, content, updateAt, imageUser, handleDelete, handleReply, commentUser, username}) => {
    const relativeTime = getRelativeTime(updateAt);
    const {cookies} = useCookies();
    return (
        <div className={`bg-[#A1A1AA10] text-white p-4 rounded-lg shadow-md ${className}`}>
            <div className="flex items-center mb-2 w-full">
                <div className="flex justify-between w-full relative">
                    <div className="flex items-center">
                        <ImageComponent className={"mr-3 w-10 h-10"} image={imageUser}/>
                        <div>
                            <h3 className="font-semibold text-[#F4F4F5]">
                                <Link to={`/user/${username}`}>
                                    {name}
                                </Link>
                            </h3>
                            <p className="text-gray-400 text-sm">{relativeTime}</p>
                        </div>
                    </div>
                    {
                        commentUser === cookies.email && (
                            <button onClick={handleDelete}
                                    className="absolute top-0 right-0 text-[#A1A1AA] hover:text-[#F4F4F5] duration-300 text-base">
                                <IoClose/>
                            </button>
                        )
                    }
                </div>
            </div>
            <div className="pt-1 w-full">
                <p className="mb-3">
                    {content}
                </p>
                <div className="flex">
                    <button onClick={handleReply} className="mr-4 flex text-[#A1A1AA] items-center text-sm hover:border-b hover:border-[#F4F4F5] transform duration-300">
                        <FiMessageSquare className={"mr-1 mt-1"}/>Reply
                    </button>
                </div>
            </div>
        </div>
    )
}

export default CommentItem