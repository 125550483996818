import React, {Fragment, useCallback, useEffect, useState} from 'react';
import MenuComponent from "../utils/menu/MenuComponent";
import ShineBorder from "../../ui/shine-border";
import {FiUsers} from "react-icons/fi";
import {RiBloggerLine} from "react-icons/ri";
import {MdDelete, MdDisplaySettings, MdEdit} from "react-icons/md";
import {FaFacebookF, FaRegComments} from "react-icons/fa";
import {Link, useNavigate, useParams} from "react-router-dom";
import {GoUnlink} from "react-icons/go";
import {IoClose, IoHourglass, IoRocket, IoWarning} from "react-icons/io5";
import {Button, Description, Field, Input, Label, Textarea} from '@headlessui/react'
import {cn} from "../../lib/utils";
import {CiCamera, CiFaceMeh} from "react-icons/ci";
import './AccountComponent.css';
import FooterComponent from "../utils/footer/FooterComponent";
import {API_BASE_URL} from "../../config";
import ImageComponent from "../utils/ImageComponent";
import ItemBlogList from "../blog/item/ItemBlogList";
import ItemPlayground from "../playground/item/ItemPlayground";
import {useCookies} from "../../cookieContext";
import StructuredData from "../utils/StructuredData";
import {LiaUndoAltSolid} from "react-icons/lia";
import {GrStatusGood} from "react-icons/gr";
import {decodeAPIKey, encodeAPIKey} from "../utils/Encrypt";

const AccountComponent = ({initialData}) => {
    const {username} = useParams();
    const [accountSettingVisible, setAccountSettingVisible] = useState(false);
    const [activeTab, setActiveTab] = useState('playground');
    const {cookies, setCookie} = useCookies()

    const [user, setUser] = useState(initialData.userData || {});
    const [listBlogs, setListBlogs] = useState([]);
    const [listExplores, setListExplores] = useState([]);
    const [editProfile, setEditProfile] = useState({
        displayName: "",
        username: "",
        fbLink: "",
        websiteUrl: "",
        bio: "",
    });
    const [error, setError] = useState({
        displayName: false,
        username: false,
        validUsername: null,
    });

    const [listAISummaries, setListAISummaries] = useState([]);
    const [aiSummariesVisible, setAISummariesVisible] = useState(false);
    const [toggleAISummaryEditVisible, setToggleAISummaryEditVisible] = useState(false);
    const [statusAITest, setStatusAITest] = useState('');
    const [aiSummaryEdit, setAISummaryEdit] = useState({
        id: "",
        model: "",
        endpoint: "",
        apiKey: "",
        isDefault: true,
        systemPrompt: "Provide a summary blog content based on the following text, including the title and author name.\n" +
            "\n" +
            "[Include any key topics, points, or details that should be covered in the summary.]\n" +
            "\n" +
            "# Output Format\n" +
            "\n" +
            "A concise summary suitable for a blog, approximately 4-6 sentences, including the title and author name. \n" +
            "\n" +
            "# Notes\n" +
            "\n" +
            "Ensure the summary is engaging and covers the main points, suitable for readers to quickly understand the core ideas.",
    })

    let [usernameCheckTimeout] = useState(null);

    const [imageUrl, setImageUrl] = useState('');
    const [isFollowing, setIsFollowing] = useState(false);


    const [isUserDataLoaded, setIsUserDataLoaded] = useState(false);
    const navigate = useNavigate();
    const emailLocal = cookies.email;
    const tokenLocal = cookies.token;
    const usernameLocal = cookies.username;

    const handleCancelAISummaryEdit = () => {
        setStatusAITest('');
        setToggleAISummaryEditVisible(false);
        setAISummaryEdit({
            id: "",
            model: "",
            endpoint: "",
            apiKey: "",
            systemPrompt: "Provide a summary blog content based on the following text, including the title and author name.\n" +
                "\n" +
                "[Include any key topics, points, or details that should be covered in the summary.]\n" +
                "\n" +
                "# Output Format\n" +
                "\n" +
                "A concise summary suitable for a blog, approximately 4-6 sentences, including the title and author name. \n" +
                "\n" +
                "# Notes\n" +
                "\n" +
                "Ensure the summary is engaging and covers the main points, suitable for readers to quickly understand the core ideas.",
        })
    }

    const handleTestAiModel = async () => {
        setStatusAITest('loading');
        try {
            const response = await fetch(`${aiSummaryEdit.endpoint}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${aiSummaryEdit.apiKey}`
                },
                body: JSON.stringify({
                    model: aiSummaryEdit.model,
                    messages: [{role: "user", content: "Hello, this is a test message."}]
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || `API request failed with status ${response.status}`);
            }

            setStatusAITest('success');
        } catch (error) {
            setStatusAITest('error');
        }  
    }

    const toggleAISummariesVisible = () => {
        if (!aiSummariesVisible) {
            void fetchAISummaries();
        }

        setAISummariesVisible(prevState => !prevState);
    }

    const fetchAISummaries = async () => {
        try {
            setListAISummaries([]);

            const response = await fetch(`${API_BASE_URL}/api/v1/ai-summary/get/all/${usernameLocal}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tokenLocal}`
                }
            });

            if (!response.ok) {
                throw new Error(`Error fetching AI summaries: ${response.status}`);
            }
            const data = await response.json();
            setListAISummaries(data);
        } catch (error) {
            console.error('Error fetching AI summaries:', error);
        }
    };

    const handleDeleteAISummary = async (id) => {
        try {
            const response = await fetch(`${API_BASE_URL}/api/v1/ai-summary/edit/delete/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tokenLocal}`
                }
            });

            if (!response.ok) {
                alert("Error deleting AI summary");
                window.location.reload();
                return;
            }

            setListAISummaries(prevList => prevList.filter(summary => summary.id !== id));
        } catch (error) {
            console.error('Error deleting AI summary:', error);
        }
    }

    const handleEditAISummary = (aiSummary) => {

        setAISummaryEdit({
            id: aiSummary.id,
            model: aiSummary.modelName,
            endpoint: aiSummary.endpoint,
            apiKey: decodeAPIKey(aiSummary.apiKey),
            systemPrompt: aiSummary.prompt,
            isDefault: aiSummary.isDefault,
        })

        setToggleAISummaryEditVisible(true);
    }

    const handleSaveAISummary = async () => {
        try {
            let add_ai = {
                modelName: aiSummaryEdit.model,
                endpoint: aiSummaryEdit.endpoint,
                apiKey: encodeAPIKey(aiSummaryEdit.apiKey),
                prompt: aiSummaryEdit.systemPrompt,
                isDefault: aiSummaryEdit.isDefault,
                token: cookies.token,
                username: usernameLocal
            }

            if (aiSummaryEdit.id.length === 0) {
                const response = await fetch(`${API_BASE_URL}/api/v1/ai-summary/edit/add`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${tokenLocal}`
                    },
                    body: JSON.stringify(add_ai)
                })

                if (!response.ok) {
                    throw new Error(`Error adding AI summary: ${response.status}`);
                }

            } else {
                const response = await fetch(`${API_BASE_URL}/api/v1/ai-summary/edit/update/${aiSummaryEdit.id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${tokenLocal}`
                    },
                    body: JSON.stringify(add_ai)
                })

                if (!response.ok) {
                    throw new Error(`Error updating AI summary: ${response.status}`);
                }

            }

            handleCancelAISummaryEdit();
        } catch (error) {
            console.error('Error adding AI summary:', error);
        } finally {
            void fetchAISummaries();
        }
    }

    const openAISummaryEditForm = () => {
        setToggleAISummaryEditVisible(prevState => !prevState);
    }

    const handleFollowAction = async (action) => {
        if (!tokenLocal) {
            navigate('/login');
            return;
        }

        const endpoint = action === 'follow'
            ? `${API_BASE_URL}/api/v1/follow/edit/add`
            : `${API_BASE_URL}/api/v1/follow/edit/delete/${user.username}/${emailLocal}`;

        const method = action === 'follow' ? 'POST' : 'DELETE';
        const body = action === 'follow' ? JSON.stringify({
            follower: user.email,
            followed: emailLocal
        }) : undefined;

        try {
            const response = await fetch(endpoint, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tokenLocal}`
                },
                body
            });

            if (!response.ok) {
                throw new Error(`Error ${action === 'follow' ? 'following' : 'unfollowing'} user: ${response.status}`);
            }

            setIsFollowing(action === 'follow');
        } catch (err) {
            console.error(err);
        }
    };

    const handleUpdateProfile = async () => {
        try {
            let user = {
                oldUsername: username,
                displayName: editProfile.displayName,
                newUsername: editProfile.username,
                fbLink: editProfile.fbLink,
                websiteUrl: editProfile.websiteUrl,
                bio: editProfile.bio,
                profileImageUrl: imageUrl,
                token: cookies.token
            }
            const response = await fetch(`${API_BASE_URL}/api/v1/user/edit/update-profile`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tokenLocal}`
                },
                body: JSON.stringify(user)
            });
            if (!response.ok) {
                throw new Error(`Error updating profile: ${response.status}`);
            }
            setCookie('username', editProfile.username);
            setCookie('profileImageUrl', imageUrl);
        } catch (error) {
            console.error('Error updating profile:', error);
        } finally {
            setAccountSettingVisible(prevState => !prevState);
            navigate(`/user/${editProfile.username}`);
            await new Promise(resolve => setTimeout(resolve, 500));
            window.location.reload();
        }
    }

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('image', file);
            formData.append('email', cookies.email);

            fetch(`${API_BASE_URL}/api/v1/image/upload`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + cookies.token
                },
                body: formData
            })
                .then(response => response.json())
                .then(data => {
                    setImageUrl(API_BASE_URL + data.url); // Prepend API_BASE_URL to the relative URL
                })
                .catch(error => console.error('Error:', error));
        }
    };

    const fetchData = useCallback(async (url, errorMessage) => {
        const response = await fetch(url);
        if (!response.ok) {
            navigate('/404');
            throw new Error(`${errorMessage}: ${response.status}`);
        }
        return response.json();
    }, []);

    const handleCheckUsername = useCallback(async (value) => {
        if (value === user.username && !error.username && value.length > 5) {
            setError({...error, validUsername: null});
        } else if (value.length > 0) {
            const data = await fetchData(`${API_BASE_URL}/api/v1/user/get/check/${value}`, 'Error checking username');
            setError({...error, validUsername: data});
        }
    }, [user, error, fetchData]);

    const fetchExplores = useCallback(async () => {
        const data = await fetchData(`${API_BASE_URL}/api/v1/playground/get/all/user/${username}`, 'Error fetching explores');
        setListExplores(data);
    }, [username, fetchData]);

    const fetchBlogs = useCallback(async () => {
        if (username === usernameLocal) {
            const data = await fetchData(`${API_BASE_URL}/api/v1/blog/get/all/${username}`, 'Error fetching blogs');
            setListBlogs(data);
        } else {
            const data = await fetchData(`${API_BASE_URL}/api/v1/blog/get/${username}`, 'Error fetching blogs');
            setListBlogs(data);
        }
    }, [username, usernameLocal, fetchData]);

    const fetchUserData = useCallback(async () => {
        const data = await fetchData(`${API_BASE_URL}/api/v1/user/get/${username}`, 'Error fetching followers');
        setUser(data);
    }, [username, fetchData]);

    const fetchIsUserFollowing = useCallback(async () => {
        const data = await fetchData(`${API_BASE_URL}/api/v1/follow/get/${user.username}/${emailLocal}`, 'Error fetching follow status');
        setIsFollowing(data);
    }, [user, emailLocal, fetchData]);

    useEffect(() => {
        if (!isUserDataLoaded) {
            if (!user.username) {
                void fetchUserData();
            }
            void fetchBlogs();
            void fetchExplores();
            setIsUserDataLoaded(true);
        }
    }, [fetchUserData, fetchBlogs, fetchExplores, isUserDataLoaded]);

    useEffect(() => {
        if (isUserDataLoaded && user.username) {
            void fetchIsUserFollowing();
        }
    }, [isUserDataLoaded, user.username, fetchIsUserFollowing]);
    const toggleAccountSettingVisible = () => {
        setEditProfile({
            displayName: user.displayName,
            username: user.username,
            fbLink: user.fbLink,
            websiteUrl: user.websiteUrl,
            bio: user.bio,
        })
        setImageUrl(user.profileImageUrl);
        setAccountSettingVisible(prevState => !prevState);
    }

    const structuredData = {
        "@context": "https://schema.org",
        "@type": "ProfilePage",
        "url": `https://w404.net/user/${user.username}`,
        "name": user.displayName,
        "description": user.bio,
        "image": "https://w404.net/assets/images/background.png",
        "sameAs": [
            user.fbLink,
            user.websiteUrl
        ]
    };


    return (
        <Fragment>
            <StructuredData imageUrl="https://w404.net/assets/images/background.png"
                            data={structuredData} title={user.displayName}
                            url={`https://w404.net/user/${user.username}`}
                            description={user.bio ? user.bio : user.displayName}/>
            <MenuComponent/>
            <div className={"container"}>
                <div className="grid h-full  grid-cols-12 mt-4 mb-8">
                    <div className="account-content-left min-h-[70dvh]">
                        <div className="flex flex-col items-center mt-12">
                            <ImageComponent className={"w-12 h-12"} image={user.profileImageUrl}
                                            alt={user.displayName}/>
                        </div>
                        <div className="mt-4 text-center">
                            <div className="text-[#F4F4F5] text-xl font-semibold">{user.displayName}</div>
                            <div className="text-[#A1A1AA] text-sm">@{user.username}</div>
                            <div className="text-[#A1A1AA] text-sm mt-2">
                                {user.bio}
                            </div>
                        </div>
                        <div className="px-8 mt-5">

                            {
                                !emailLocal ? (
                                    <ShineBorder
                                        className={"text-center px-4 py-2 capitalize opacity-50 cursor-not-allowed"}>Login
                                        to Follow</ShineBorder>
                                ) : user.email === emailLocal ? (
                                    <ShineBorder
                                        className={"text-center px-4 py-2 capitalize opacity-50 cursor-not-allowed"}>Follow</ShineBorder>
                                ) : isFollowing ? (
                                    <ShineBorder onClick={() => handleFollowAction('unfollow')}
                                                 className={"text-center px-4 py-2 capitalize cursor-pointer"}>Following</ShineBorder>
                                ) : (
                                    <ShineBorder onClick={() => handleFollowAction('follow')}
                                                 className={"text-center px-4 py-2 capitalize cursor-pointer"}>Follow</ShineBorder>
                                )
                            }

                            {
                                user.email === emailLocal && (
                                    <Fragment>
                                        <button onClick={toggleAccountSettingVisible}
                                                className={"text-[#A1A1AA] mt-3 w-full py-2 rounded-lg border hover:text-[#F4F4F5] duration-300"}>Edit
                                            Profile
                                        </button>
                                        <button onClick={toggleAISummariesVisible}
                                                className={"mt-3 w-full py-2 rounded-lg hover:text-[#F4F4F5] duration-300 animate-gradient bg-gradient-to-r from-[#ffaa40] via-[#9c40ff] to-[#ffaa40] bg-[length:var(--bg-size)_100%]"}>
                                            <span className={"text-transparent bg-clip-text bg-white"}>
                                                Magic Summary Settings
                                            </span>
                                        </button>
                                    </Fragment>
                                )
                            }


                        </div>
                        <div className="mt-9 px-2 py-4"
                             style={{borderBottom: "1px solid #A1A1AA", borderTop: "1px solid #A1A1AA"}}>
                            <div className="text-[#A1A1AA] flex justify-between items-center">
                                <div className="flex flex-col items-center">
                                    <div className="flex items-center">
                                        <FiUsers/>
                                        <span className="ml-1">{user.followerCount}</span>
                                    </div>
                                    <span className="account-text-user-active text-xs uppercase mt-1">Followers</span>
                                </div>

                                <div className="flex flex-col items-center">
                                    <div className="flex items-center">
                                        <RiBloggerLine/>
                                        <span className="ml-1">{listBlogs.length}</span>
                                    </div>
                                    <span className="account-text-user-active text-xs uppercase mt-1">Blogs</span>
                                </div>

                                <div className="flex flex-col items-center">
                                    <div className="flex items-center">
                                        <MdDisplaySettings/>
                                        <span className="ml-1">{listExplores.length}</span>
                                    </div>
                                    <span className="account-text-user-active text-xs uppercase mt-1">Explores</span>
                                </div>

                                <div className="flex flex-col items-center">
                                    <div className="flex items-center">
                                        <FaRegComments/>
                                        <span className="ml-1">{user.commentCount}</span>
                                    </div>
                                    <span className="account-text-user-active text-xs uppercase mt-1">Comments</span>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5">
                            {
                                user.fbLink?.length > 0 && (
                                    <div className="flex items-center text-[#A1A1AA] overflow-hidden">
                                        <FaFacebookF className={"mr-2"}/>
                                        <Link to={user.fbLink}>{user.fbLink}</Link>
                                    </div>
                                )
                            }
                            {
                                user.websiteUrl?.length > 0 && (
                                    <div className="flex items-center text-[#A1A1AA] overflow-hidden mt-3">
                                        <GoUnlink className={"mr-2"}/>
                                        <Link to={user.websiteUrl}>{user.websiteUrl}</Link>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className="account-content-right">
                        <div className="flex mb-4 justify-end">
                            <button
                                className={`text-xl px-4 py-2 font-semibold ${
                                    activeTab === 'playground' ? 'text-[#F4F4F5] border-b-2 border-[#F4F4F5]' : 'text-[#A1A1AA]'
                                }`}
                                onClick={() => setActiveTab('playground')}
                            >
                                Explore
                            </button>
                            <button
                                className={`text-xl px-4 py-2 font-semibold ${
                                    activeTab === 'blog' ? 'text-[#F4F4F5] border-b-2 border-[#F4F4F5]' : 'text-[#A1A1AA]'
                                }`}
                                onClick={() => setActiveTab('blog')}
                            >
                                Blog
                            </button>
                        </div>

                        {activeTab === 'playground' && (
                            <div className="account-playground-content my-5">

                                {
                                    listExplores.length > 0 ? (
                                        listExplores
                                            .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
                                            .map((item, index) => (
                                            <ItemPlayground key={index} item={item}/>
                                        ))
                                    ) : (
                                        <div className={"text-[#A1A1AA] w-full"}>
                                        <span className={"items-center gap-1 flex justify-center underline"}>
                                            None yet or None published yet. <CiFaceMeh/>
                                        </span>
                                        </div>
                                    )
                                }

                            </div>
                        )}

                        {activeTab === 'blog' && (
                            <div className={"flex px-10 my-5 transform duration-300"}
                                 style={{flexWrap: "wrap", flexDirection: "row", gap: "18px"}}>
                                {
                                    listBlogs.length > 0 ? (
                                        listBlogs
                                            .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
                                            .map((blog, index) => (
                                            <ItemBlogList
                                                key={index}
                                                shortDescription={blog.shortDescription}
                                                slug={blog.slug}
                                                title={blog.title}
                                                viewCount={blog.viewCount}
                                                imageUrl={blog.imageUrl}
                                                createdAt={blog.updatedAt}
                                                user={blog.user}
                                                status={blog.status}
                                            />
                                        ))
                                    ) : (
                                        <div className={"text-[#A1A1AA] w-full"}>
                                        <span className={"items-center gap-1 flex justify-center underline"}>
                                            None yet or None published yet. <CiFaceMeh/>
                                        </span>
                                        </div>
                                    )
                                }
                            </div>
                        )}
                    </div>
                </div>


                {/*Modal Account Setting*/}
                {
                    accountSettingVisible && (
                        <div id="default-modal"
                             className={"flex items-center justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[100] w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"}>
                            <div className="relative p-4 w-full max-w-2xl max-h-full">
                                {/*Modal content */}
                                <div className="relative rounded-2xl shadow bg-[#121212]">
                                    {/*<!-- Modal header*/}
                                    <div
                                        className="flex items-center justify-between pt-5 pb-2 px-5 border-b rounded-t border-gray-600">
                                        <h3 className="text font-semibold text-[#F4F4F5]">
                                            Account Settings
                                        </h3>
                                        <button onClick={toggleAccountSettingVisible} type="button"
                                                className="text-[#A1A1AA] text-xl rounded-lg w-8 h-8 ms-auto inline-flex justify-center items-center hover:text-white duration-300"
                                                data-modal-hide="default-modal">
                                            <IoClose/>
                                            <span className="sr-only">Close modal</span>
                                        </button>
                                    </div>
                                    {/*<!-- Modal body*/}
                                    <div className="p-4 md:p-5 space-y-3">
                                        <div className="flex flex-col items-center mt-3 relative">
                                            <div className="relative">
                                                <ImageComponent
                                                    className="w-14 h-[3.5rem] object-cover overflow-hidden"
                                                    image={imageUrl}
                                                    alt={user.displayName}
                                                />
                                                <label htmlFor="fileInput"
                                                       className="tow absolute inset-0 flex justify-center items-center"
                                                >
                                                    <div
                                                        className="text-white bg-gray-500 bg-opacity-50 text-3xl rounded cursor-pointer p-1 hover:bg-opacity-75 transition-all">
                                                        <CiCamera/>
                                                    </div>
                                                </label>
                                            </div>
                                            <input
                                                id="fileInput"
                                                type="file"
                                                accept="image/*"
                                                className="hidden"
                                                onChange={handleImageUpload}
                                            />
                                        </div>

                                        <Field>
                                            <Label className="text-sm/6 font-medium text-white">Name</Label>
                                            <Input onChange={(e) => {
                                                const newName = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
                                                setEditProfile({...editProfile, displayName: newName})
                                                setError({...error, displayName: newName.length > 0 && newName.length < 5})
                                            }}
                                                   value={editProfile.displayName}
                                                   minLength={5}
                                                   maxLength={150}
                                                   className={cn(
                                                       'mt-3 block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white',
                                                       'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                                                   )}
                                            />
                                            {
                                                error.displayName && (
                                                    <span
                                                        className={"text-sm text-red-400 mt-1"}>Name need longer than 5 characters!</span>
                                                )
                                            }
                                        </Field>
                                        <Field>
                                            <Label className="text-sm/6 font-medium text-white">Username</Label>
                                            <Input
                                                onChange={(e) => {
                                                    const newUsername = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '').trim();
                                                    setEditProfile(prevState => ({...prevState, username: newUsername}));
                                                    setError(prevError => ({
                                                        ...prevError,
                                                        username: newUsername.length < 5
                                                    }));

                                                    clearTimeout(usernameCheckTimeout);
                                                    usernameCheckTimeout = setTimeout(() => {
                                                        if (newUsername.length >= 5) {
                                                            void handleCheckUsername(newUsername);
                                                        }
                                                    }, 900);
                                                }}
                                                value={editProfile.username}
                                                minLength={5}
                                                maxLength={150}
                                                className={cn(
                                                    'mt-3 block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white',
                                                    'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                                                )}
                                            />
                                            {
                                                editProfile.username.length > 0 && editProfile.username.length < 5 && (
                                                    <span className="text-sm text-red-400 mt-1">Username needs to be longer than 5 characters!</span>
                                                )
                                            }

                                            {
                                                error.validUsername !== undefined && user.username !== editProfile.username && error.username === false && editProfile.username.length > 0 && (
                                                    error.validUsername ? (
                                                        <span
                                                            className="block text-sm text-green-400 mt-1">Username is available!</span>
                                                    ) : (
                                                        <span
                                                            className="block text-sm text-red-400 mt-1">Username is already taken!</span>
                                                    )
                                                )
                                            }

                                        </Field>
                                        <Field>
                                            <Label className="text-sm/6 font-medium text-white">Facebook (optional)</Label>
                                            <Input
                                                onChange={(e) => setEditProfile({...editProfile, fbLink: e.target.value})}
                                                value={editProfile.fbLink || ''}
                                                minLength={2}
                                                maxLength={200}
                                                className={cn(
                                                    'mt-3 block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white',
                                                    'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                                                )}
                                            />
                                        </Field>
                                        <Field>
                                            <Label className="text-sm/6 font-medium text-white">Link (optional)</Label>
                                            <Input
                                                onChange={(e) => setEditProfile({
                                                    ...editProfile,
                                                    websiteUrl: e.target.value
                                                })}
                                                value={editProfile.websiteUrl || ''}
                                                minLength={2}
                                                maxLength={200}
                                                className={cn(
                                                    'mt-3 block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white',
                                                    'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                                                )}
                                            />
                                        </Field>
                                        <Field>
                                            <Label className="text-sm/6 font-medium text-white">Bio (optional)</Label>
                                            <Textarea onChange={e => setEditProfile({...editProfile, bio: e.target.value})}
                                                      value={editProfile.bio || ''}
                                                      minLength={2}
                                                      maxLength={200}
                                                      className={cn(
                                                          'mt-3 block w-full resize-none rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white',
                                                          'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                                                      )}
                                                      rows={3}
                                            />
                                        </Field>
                                    </div>
                                    {/*<!-- Modal footer */}
                                    <div
                                        className="flex items-center p-4 md:p-5 border-t rounded-b border-gray-600">
                                        <div className="flex items-center w-full justify-center">
                                            <Button
                                                onClick={handleUpdateProfile}
                                                disabled={
                                                    editProfile.displayName.length < 5 ||
                                                    editProfile.username.length < 5 ||
                                                    (editProfile.username !== user.username && error.validUsername === false)
                                                }
                                                className={`inline-flex duration-300 items-center gap-2 rounded-md py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none ${
                                                    editProfile.displayName.length < 5 ||
                                                    editProfile.username.length < 5 ||
                                                    (editProfile.username !== user.username && error.validUsername === false)
                                                        ? 'bg-gray-500 cursor-not-allowed'
                                                        : 'bg-gray-700 data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white'
                                                }`}
                                            >
                                                Save changes
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    )
                }

                {/*Modal AI Summaries*/}
                {
                    aiSummariesVisible && (
                        <div id="default-modal"
                             className={"flex items-center justify-center overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[100] w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"}>
                            <div className="relative p-4 w-full max-w-2xl max-h-full">
                                {/*Modal content */}
                                <div className="relative rounded-2xl shadow bg-[#121212]">
                                    {/*<!-- Modal header*/}
                                    <div
                                        className="flex items-center justify-between pt-5 pb-2 px-5 border-b rounded-t border-gray-600">
                                        <h3 className="inline animate-gradient bg-gradient-to-r from-[#ffaa40] via-[#9c40ff] to-[#ffaa40] bg-[length:var(--bg-size)_100%] bg-clip-text text-transparent">
                                            AI Magic Summary Settings
                                        </h3>
                                        <button onClick={toggleAISummariesVisible} type="button"
                                                className="text-[#A1A1AA] text-xl rounded-lg w-8 h-8 ms-auto inline-flex justify-center items-center hover:text-white duration-300"
                                                data-modal-hide="default-modal">
                                            <IoClose/>
                                            <span className="sr-only">Close modal</span>
                                        </button>
                                    </div>
                                    {/*<!-- Modal body*/}
                                    <div className="grid gap-4 pt-4 pb-6 px-5">
                                        {
                                            listAISummaries.length > 0 && listAISummaries.filter(item => item.isDefault).length === 0 && (
                                                <div>
                                                    <span className="text-red-500 text-sm underline">
                                                        You need to set one model as default.
                                                    </span>
                                                </div>
                                                )
                                        }

                                        <div className="grid gap-2">

                                            {
                                                listAISummaries.length > 0 ? (
                                                    listAISummaries.map((item, index) => (
                                                        <div
                                                            key={index}
                                                            className="grid grid-cols-12 border rounded-xl p-3 text-white">
                                                            <div className="col-span-10">
                                                                <span className={"flex mb-2 items-center"}>
                                                                    {item.modelName}
                                                                    {
                                                                        item.isDefault && (
                                                                            <GrStatusGood
                                                                                className={"text-green-500 ml-1"}/>
                                                                        )
                                                                    }
                                                                </span>
                                                                <span>
                                                                    {item.endpoint}
                                                                </span>
                                                            </div>
                                                            <div className="col-span-1 m-auto text-base">
                                                                <div onClick={() => handleEditAISummary(item)}
                                                                     className={"cursor-pointer hover:border hover:border-gray-500 hover:rounded-3xl hover:text-gray-500 duration-300"}>
                                                                    <MdEdit/>
                                                                </div>
                                                            </div>
                                                            <div className="col-span-1 m-auto text-base">
                                                                <div onClick={() => handleDeleteAISummary(item.id)}
                                                                     className={"cursor-pointer hover:border hover:border-gray-500 hover:rounded-3xl hover:text-gray-500 duration-300"}>
                                                                    <MdDelete/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))) : (
                                                    <div className="text-[#A1A1AA] w-full text-center">
                                                      <span className="text-sm">
                                                        How Do We Securely Store Your API Key? We encrypt it before saving it to our database.
                                                        <Link className="underline italic ml-1" to="/privacy">
                                                          Learn More
                                                        </Link>
                                                      </span>
                                                    </div>

                                                )
                                            }

                                        </div>

                                        {/*Add models*/}
                                        <Button onClick={openAISummaryEditForm}
                                                className="button-ai-summary-add-new-model ">
                                            Add New Model
                                        </Button>

                                        {
                                            toggleAISummaryEditVisible && (
                                                <div className="grid gap-2">
                                                    <Field>
                                                        <div className="flex items-center">
                                                            <span
                                                                className="text-sm/6 font-medium text-white mr-3">Is Default:</span>
                                                            <label
                                                                className="relative inline-flex items-center cursor-pointer z-0">
                                                                <input
                                                                    className="sr-only peer"
                                                                    type="checkbox"
                                                                    checked={aiSummaryEdit.isDefault}
                                                                    onChange={(e) => setAISummaryEdit({
                                                                        ...aiSummaryEdit,
                                                                        isDefault: e.target.checked
                                                                    })}

                                                                />
                                                                <div
                                                                    className="peer rounded-2xl outline-none duration-100 after:duration-500 w-[80px] h-7 bg-[#363636] peer-focus:outline-none after:content-['N'] after:absolute after:outline-none after:h-5 after:w-8 after:bg-white after:top-1 after:left-1 after:flex after:justify-center after:items-center  after:text-sky-800 after:font-bold peer-checked:after:translate-x-10 peer-checked:after:content-['Y'] peer-checked:after:border-white after:rounded-2xl after:px-2">
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </Field>

                                                    <Field>
                                                        <Label className="text-sm/6 font-medium text-white">
                                                            Model request name
                                                        </Label>
                                                        <Input
                                                            onChange={(e) => setAISummaryEdit({
                                                                ...aiSummaryEdit,
                                                                model: e.target.value
                                                            })}
                                                            value={aiSummaryEdit.model || ''}
                                                            minLength={2}
                                                            maxLength={900}
                                                            placeholder={"gpt-4o"}
                                                            className={cn(
                                                                'mt-1 block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white',
                                                                'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                                                            )}
                                                        />

                                                        {
                                                            aiSummaryEdit.model.length > 0 && aiSummaryEdit.model.length < 5 && (
                                                                <span className="text-sm text-red-400 mt-1">Model request name needs to be longer than 5 characters!</span>
                                                            )
                                                        }
                                                    </Field>
                                                    <Field>
                                                        <Label className="text-sm/6 font-medium text-white">
                                                            Service endpoint
                                                        </Label>
                                                        <Input
                                                            onChange={(e) => setAISummaryEdit({
                                                                ...aiSummaryEdit,
                                                                endpoint: e.target.value
                                                            })}
                                                            value={aiSummaryEdit.endpoint || ''}
                                                            minLength={2}
                                                            maxLength={900}
                                                            placeholder={"https://api.openai.com/v1/chat/completions"}
                                                            className={cn(
                                                                'mt-1 block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white',
                                                                'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                                                            )}
                                                        />

                                                        {
                                                            aiSummaryEdit.endpoint.length > 0 && aiSummaryEdit.endpoint.length < 5 && (
                                                                <span className="text-sm text-red-400 mt-1">Service endpoint needs to be longer than 5 characters!</span>
                                                            )
                                                        }
                                                    </Field>
                                                    <Field>
                                                        <Label className="text-sm/6 font-medium text-white">
                                                            API Key
                                                        </Label>
                                                        <Description className="text-sm/6 text-white/50">
                                                            Your API key will be encoded before being saved to our database.
                                                        </Description>
                                                        <Input
                                                            onChange={(e) => setAISummaryEdit({
                                                                ...aiSummaryEdit,
                                                                apiKey: e.target.value
                                                            })}
                                                            value={aiSummaryEdit.apiKey || ''}
                                                            minLength={2}
                                                            maxLength={1900}
                                                            placeholder={"sk-1234567890"}
                                                            className={cn(
                                                                'mt-1 block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white',
                                                                'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                                                            )}
                                                        />

                                                        {
                                                            aiSummaryEdit.apiKey.length > 0 && aiSummaryEdit.apiKey.length < 5 && (
                                                                <span className="text-sm text-red-400 mt-1">API Key needs to be longer than 5 characters!</span>
                                                            )
                                                        }
                                                    </Field>
                                                    <Field>
                                                        <Label className="text-sm/6 font-medium text-white">
                                                            System Prompt
                                                        </Label>
                                                        <Textarea
                                                            rows={3}
                                                            onChange={(e) => setAISummaryEdit({
                                                                ...aiSummaryEdit,
                                                                systemPrompt: e.target.value
                                                            })}
                                                            value={aiSummaryEdit.systemPrompt || ''}
                                                            minLength={2}
                                                            maxLength={300}
                                                            className={cn(
                                                                'mt-1 block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white',
                                                                'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                                                            )}
                                                        />

                                                        {
                                                            aiSummaryEdit.systemPrompt.length > 0 && aiSummaryEdit.systemPrompt.length < 5 && (
                                                                <span className="text-sm text-red-400 mt-1">System Prompt needs to be longer than 5 characters!</span>
                                                            )
                                                        }
                                                    </Field>

                                                    <div className="mt-3 flex justify-between gap-2">
                                                        <button onClick={() => handleTestAiModel()}
                                                                className={"flex text-sm text-gray-500 items-center"}>
                                                            {
                                                                statusAITest === 'loading' ? (
                                                                    <span className="animate-spin">
                                                                        <IoHourglass/>
                                                                    </span>
                                                                ) : statusAITest === 'success' ? (
                                                                    <span className="flex items-center text-green-500">
                                                                        <LiaUndoAltSolid className={"mr-1"}/> Success
                                                                    </span>
                                                                ) : statusAITest === 'error' ? (
                                                                    <span className="flex items-center text-red-500">
                                                                        <IoWarning className={"mr-1"}/> Error
                                                                    </span>
                                                                ) : (
                                                                    <span className={"flex items-center"}>
                                                                        <IoRocket className={"mr-1"}/> Test
                                                                    </span>
                                                                )
                                                            }
                                                        </button>

                                                        <div className="flex gap-x-2">
                                                            <Button
                                                                onClick={handleCancelAISummaryEdit}
                                                                className="inline-flex items-center gap-2 rounded-md bg-gray-700 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white">
                                                                Cancel
                                                            </Button>
                                                            <Button disabled={aiSummaryEdit.model.length < 5 || aiSummaryEdit.endpoint.length < 5 || aiSummaryEdit.apiKey.length <5 || aiSummaryEdit.systemPrompt.length < 5}
                                                                onClick={handleSaveAISummary}
                                                                className="inline-flex items-center gap-2 rounded-md bg-green-500 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-green-400 data-[open]:bg-green-500 data-[focus]:outline-1 data-[focus]:outline-white">
                                                                {
                                                                    aiSummaryEdit.id.length === 0 ? 'Add Model' : 'Update'
                                                                }
                                                            </Button>
                                                        </div>
                                                    </div>

                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    )
                }

            </div>
            <FooterComponent/>
        </Fragment>
    );

}

export default AccountComponent;